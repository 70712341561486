@import "sizes";
@import "variables";

.yg-btn {
    border: none;
    width: 100%;
    border-radius: 12px;
    box-shadow: none !important;
    padding: 8px 55px;
}
.yg-btn-min {
    border: none;
    width: 100%;
    border-radius: 12px;
    box-shadow: none !important;
    padding: 8px 0;
}
.ReactModal__Overlay {
    opacity: 0;
    transform: translateX(100px);
    transition: all 50ms ease-in-out;
}
.ReactModal__Overlay--after-open {
    opacity: 1;
    transform: translateX(0px);
}
.ReactModal__Overlay--before-close {
    opacity: 0;
    transform: translateX(100px);
}
.yg-btn-thin {
    border: none;
    width: 100%;
    border-radius: 12px;
    box-shadow: none !important;
    padding: 8px 0;
    margin: 0 10px;
}
.yg-btn-red {
    background: transparent;
    color: red;
    outline: none;
    border: none;
}
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    margin-bottom: 0;
}
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.1s;
    transition: 0.1s;
}
.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.1s;
    transition: 0.1s;
}
input:checked + .slider {
    background-color: $black-neutral;
}
input:focus + .slider {
    box-shadow: 0 0 1px $black-neutral;
}
input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}
.toggle {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 35%;
    margin-top: 50px;
    border: 2px solid $orange-primary;
    border-radius: 12px;
    & input {
        display: none;
    }
    & input:checked ~ label .toggler {
        left: 0%;
    }
    & input + label .toggler {
        left: 50%;
    }
    & label {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        margin-bottom: 0;
        padding: 4px 0;
        & .toggleLabel {
            padding: 0.25em;
            min-height: 1em;
            min-width: 1em;
            text-align: center;
            z-index: 1;
            flex: 1;
            transition: all 0.3s;
            font-weight: bold;
            color: $black-neutral;
        }
        & .toggleLabelChecked {
            padding: 0.25em;
            min-height: 1em;
            min-width: 1em;
            text-align: center;
            z-index: 1;
            flex: 1;
            transition: all 0.3s;
            font-weight: bold;
            color: white;
        }
    }
}
.toggler {
    position: absolute;
    left: -2px;
    width: 50%;
    height: 100%;
    background: $orange-primary;
    transition: all 0.3s;
    border-radius: 12px;
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% {  transform: rotate(359deg); }
}
#animlogo {
    animation: spin 2s linear infinite;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -25px 0 0 -25px;
}
#animlogo-signup{
    width: 25px;
    animation: spin 2s linear infinite;
}
.animlogo-div{
    position: absolute;
    width: 100%;
    height: 100%;
    background: #000000ad;
    z-index: 100;
}
/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}
.slider.round:before {
    border-radius: 50%;
}
.yg-btn-black {
    background: $black-neutral;
    color: $white;
}
.yg-btn-white-w {
    color: $black-neutral;
    background: $white;
}
.yg-btn-black:hover,
.yg-btn-black:active {
    background: $black-neutral;
    box-shadow: 0 8px 15px rgb(0 0 0 / 49%) !important;
    color: $white;
}
.yg-btn-white {
    color: $black-neutral;
    background: transparent;
    border: 2px $black-neutral solid;
}
.yg-btn-gray {
    color: $gray-light-neutral;
    background: transparent;
}
.svg-orange {
    filter: invert(45%) sepia(85%) saturate(2922%) hue-rotate(355deg) brightness(98%) contrast(113%);
}
.connect-bank-from-child {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    justify-content: center;
}
.w-50 {
    width: 50%;
}
.w-40 {
    width: 40%;
}
.w-60 {
    width: 60%;
}
.w-70 {
    width: 70%;
}
.w-80 {
    width: 80% !important;
}
.w-90 {
    width: 90%;
}
.w-100 {
    width: 100%;
}
.w-33 {
    width: 33%;
}
.w-260 {
    width: 260px;
}
.mr-5 {
    margin: 0 5px 0 0 !important;
}
.ml-5 {
    margin: 0 0 0 5px !important;
}
.p-10-5 {
    padding: 10px 5px !important;
}
.p-5-30 {
    padding: 5px 30px !important;
}
#yg-password,
#yg-name,
#yg-email,
#yg-phone,
#yg-birthday,
#yg-allowance-day {
    border: none;
    border-radius: 0;
    box-shadow: none;
    background: transparent;
}

#yg-password:focus,
#yg-name:focus,
#yg-email:focus,
#yg-phone:focus,
#yg-birthday:focus,
#yg-allowance-day:focus {
    color: $orange-primary;
}
._2Jtxm._35Ago ._2kL0S {
    background-color: $orange-primary !important;
}
._2Jtxm._2ZUAI ._2JvrO {
    background-color: $orange-primary !important;
}
._2Jtxm::before {
    background: $orange-primary !important;
}
._2Jtxm ._2kL0S,
._2Jtxm ._2JvrO {
    border: 1px $orange-primary solid !important;
}
._1Lo2h {
    padding: 2rem 1rem 0 1rem !important;
}
._53Ji7 {
    padding: 0 !important;
}
._3uApM {
    position: fixed !important;
    bottom: 15px !important;
    right: 15px !important;
    z-index: 100 !important;
    font-size: 10px !important;
}
._hsN1w {
    background-color: $orange-primary !important;
    border-radius: 100% !important;
    margin-left: 6px !important;
    padding: 0.5rem 0.75rem !important;
    border: none !important;
}
._hsN1w:focus,
._hsN1w:active {
    background-color: transparent !important;
}
._3CDiP {
    border: 1px solid $orange-primary !important;
    background-color: $white-primary !important;
    border-radius: 100% !important;
    padding: 0.5rem 0.75rem !important;
}
._2Jtxm ._2kL0S {
    height: 24px !important;
    width: 24px !important;
}
._2Jtxm ._2JvrO {
    height: 36px !important;
    width: 36px !important;
}
.rs-steps-item-title {
    font-size: 10px;
    font-weight: bold;
    line-height: unset;
    padding-right: 0;
    width: 50px;
    text-align: center;
}
.rs-steps-item-content {
    margin: 40px 0 0 -40px;
}
.back-button-signup {
    color: $white;
    background: $orange-primary;
    border-radius: 100%;
    padding: 5px 11px 5px 10px;
    position: fixed;
    bottom: 25px;
    right: 25px;
}
.rs-steps-horizontal .rs-steps-item-title:after {
    display: none;
}
.rs-steps-horizontal {
    padding: 20px 0 0 10px;
}
.rs-steps-horizontal .rs-steps-item .rs-steps-item-icon-wrapper {
    left: 15px;
}
.rs-steps-horizontal .rs-steps-item {
    padding-left: 50px;
}
.rs-steps-item-status-process .rs-steps-item-icon-wrapper {
    border-color: $orange-primary;
    border-color: $orange-primary;
    background-color: $orange-primary;
    background-color: $orange-primary;
}
.rs-steps-item-status-finish .rs-steps-item-icon-wrapper {
    border-color: $orange-primary;
    border-color: $orange-primary;
    color: $white;
    background: $orange-primary;
}
.rs-steps-item-icon-wrapper {
    background: #e7eaf3;
    border: 1px solid $orange-primary;
}
.steps-line {
    height: 2.2px;
    background: $orange-primary;
    margin: -70px 33px 55px 44px;
}
.rs-steps-item-icon-wrapper > .rs-steps-item-icon .rs-icon {
    font-size: 18px;
    margin: -2px 0 2px 0;
}
.rb-input,
.rb-input-blue,
.rb-input-orange {
    visibility: hidden;
    height: 0;
    width: 0;
}
.birthday-picker {
    border: none !important;
}
.react-date-picker__wrapper {
    border: none !important;
}
.react-date-picker__button:enabled,
.react-date-picker__button:focus {
    outline: none !important;
}
.react-date-picker__inputGroup__input--hasLeadingZero {
    outline: none !important;
    border: none !important;
}
.react-date-picker__inputGroup__input {
    outline: none !important;
    border: none !important;
}
.react-date-picker__inputGroup__input:invalid {
    background: transparent !important;
}
.rb-input-text-blue,
.rb-input-text-blue:focus,
.rb-input-text-blue:active {
    background: transparent;
    border: none;
    box-shadow: none;
    outline: none;
    width: 100%;
    padding: 10px 25px;
    color: $blue-secondary;
}
.rb-input-text-orange,
.rb-input-text-orange:focus,
.rb-input-text-orange:active {
    background: transparent;
    border: none;
    box-shadow: none;
    outline: none;
    width: 100%;
    padding: 10px 25px;
    color: $orange-primary;
}
.rb-input-text-black,
.rb-input-text-black:focus,
.rb-input-text-black:active {
    background: transparent;
    border: none;
    box-shadow: none;
    outline: none;
    width: 100%;
    padding: 10px 25px;
    color: $black-neutral;
}
.rb-label {
    border-radius: 12px !important;
    padding: 10px 20px !important;
    cursor: pointer !important;
    font-size: 12px !important;
    margin-bottom: 0;
    text-align: center;
}
.rb-label-dashboard {
    border-radius: 12px !important;
    padding: 7px 20px !important;
    cursor: pointer !important;
    font-size: 12px !important;
    margin-bottom: 0;
    width: 25%;
    text-align: center;
}
.rb-input:checked + label {
    background-color: $black-neutral;
    color: $white;
    transition: all 150ms linear;
}
.rb-input-blue:checked + label,
.rb-input-blue:checked + span {
    background-color: $blue-secondary;
    color: $white !important;
}
.rb-input-orange:checked + label {
    background-color: $orange-primary;
    color: $white;
}
.rb-input-color-blue {
    width: 40px;
    height: 40px;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: $blue-secondary !important;
    border-radius: 100%;
}
.rb-input-color-orange {
    width: 40px;
    height: 40px;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: $orange-primary !important;
    border-radius: 100%;
}
.rb-input-color-blue:checked,
.rb-input-color-orange:checked {
    border: 2px $black-neutral solid;
}
.yg-radio-form-color {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(4, 1fr);
}
.yg-gradient {
    background: $gradient;
    border-radius: 12px;
}
.yg-gradient-blue {
    background: $white-secondary;
    border-radius: 12px;
}
.yg-gradient-orange {
    background: $white-primary;
    border-radius: 12px;
}
.yg-input-other {
    border-bottom: 1px $black-neutral solid;
    display: flex;
    align-items: center;
}
.setallowance-form-group {
    display: flex;
    width: 80%;
    flex-direction: column;
}
.yg-radio-form-sa {
    display: flex;
    align-items: center;
}
.yg-password-form {
    display: flex;
    align-items: center;
    border-bottom: 1px $black-neutral solid;
}
.yg-password-form:focus-within {
    display: flex;
    align-items: center;
    border-bottom: 1px $orange-primary solid !important;
}
.yg-login-row {
    align-items: stretch;
    display: flex;
    background-color: $black-neutral;
}
.goals-chart {
    background: $white-secondary;
    border-radius: 12px;
    padding: 10px 25px 20px 25px;
}
.goals-lowtitle {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 25px 0;
}
.goals-lowtitle-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px $white solid;
    padding: 5px 0 10px 0;
    margin-bottom: 15px;
}
.untrg-savings {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 25px;
}
.bank-accounts {
    display: flex;
    flex-direction: column;
    padding: 10px 25px;
}
.bank-accounts:hover, .bank-accounts:focus {
    background: $black-neutral;
    border: none;
    outline: none;
}
.bank-accounts:hover .yg-headline-label-light-s-black, .bank-accounts:hover .yg-headline-label-light-xs-black {
    color: white;
}
.bank-accounts:focus .yg-headline-label-light-s-black, .bank-accounts:focus .yg-headline-label-light-xs-black {
    color: white;
}
.yg-loginpage-row {
    align-items: center;
    height: 100vh;
    display: flex;
    background-size: cover;
    background-image: url("../svg/background.svg");
}
.yg-login-left {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 0 100px 0;
}
.yg-goals-right {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.yg-dashboard-left {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
    width: 100%;
    transition: all 0.5s ease;
    z-index: 100;
}
.yg-login-form {
    backdrop-filter: blur(6px);
    background: rgba(255, 255, 255, 0.2);
    height: 100%;
}
.yg-signin-form {
    backdrop-filter: blur(6px);
    background: rgb(205 205 205 / 20%);
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.signin-switch {
    margin-top: 50px;
    margin-left: -15px;
}
input:-internal-autofill-selected {
    background-color: transparent !important;
}
.forgot_password-span {
    font-size: 12px;
    color: $black-neutral;
}
.yg-headline-label-dark-xs {
    font-size: 12px;
    color: $black-neutral;
    font-weight: bold;
}
.yg-headline-label-light-xs {
    font-size: 12px;
    color: white;
    font-weight: bold;
}
.navmenu-button:hover .navbar-icon {
    filter: invert(45%) sepia(85%) saturate(2922%) hue-rotate(355deg) brightness(98%) contrast(113%);
}
.navmenu-button:hover .yg-headline-label-light-xs {
    color: $orange-primary;
    text-shadow: 0 8px 15px rgb(0 0 0 / 49%) !important;
}
.yg-headline-label-light-xxs {
    font-size: 10px;
    color: white;
    font-weight: bold;
}
.yg-headline-label-light-xxs-white {
    font-size: 10px;
    color: white;
    font-weight: bold;
}
.yg-headline-label-light-xxs-black-neutral {
    font-size: 10px;
    color: $black-neutral;
    font-weight: bold;
}
.yg-headline-label-light-xs-blue {
    font-size: 14px;
    color: $blue-secondary;
    font-weight: bold;
}
.yg-headline-label-light-xs-green {
    font-size: 14px;
    color: $green-success;
    font-weight: bold;
}
.yg-headline-label-light-xs-orange {
    font-size: 14px;
    color: $orange-primary;
    font-weight: bold;
}
.yg-headline-label-light-xxs-orange {
    font-size: 12px;
    color: $orange-primary;
    font-weight: bold;
}
.yg-headline-label-light-xs-white {
    font-size: 14px;
    color: $white;
    font-weight: bold;
}
.yg-headline-label-light-xs-black {
    font-size: 14px;
    color: $black-neutral;
    font-weight: bold;
}
.yg-headline-label-light-xxs-black {
    font-size: 12px;
    color: $black-neutral;
    font-weight: bold;
}
.yg-headline-label-light-s-blue {
    font-size: 16px;
    color: $blue-secondary;
    font-weight: bold;
}
.child-info-block{
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    background: $black-neutral;
    color: white;
    border: none;
    width: 100%;
    border-radius: 12px;
}
.expenses-progress{
    width: 51px;
    padding-right: 10px;
}
.yg-headline-label-light-s-green {
    font-size: 16px;
    color: $green-success;
    font-weight: bold;
}
.yg-headline-label-light-s-orange {
    font-size: 16px;
    color: $orange-primary;
    font-weight: bold;
}
.yg-headline-label-light-s-black {
    font-size: 16px;
    color: $black-neutral;
    font-weight: bold;
}
.chores-single-gap-blue {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: $white-secondary;
    border-radius: 14px;
    padding: 12px 0;
}
.chores-single-gap-orange {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: $white-primary;
    border-radius: 14px;
    padding: 12px 0;
}
.chores-grid {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 15px;
    padding: 24px 0;
}
.create-accout-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: center;
    padding-left: 5px;
}
.code-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: center;
    padding: 60px 0 0 0;
    padding-left: 5px;
}
.bank-aclist-form{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: center;
    padding: 35px 0 0 0;
}
.bank-list{
    height: 430px;
    overflow-y: scroll;
}
.change-email-modal-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: center;
    padding: 0;
}
.yg-select-back,
.yg-select-back:active,
.yg-select-back:focus,
.yg-select-back:hover {
    position: fixed;
    left: 30px;
    top: 30px;
    width: 40px;
    height: 40px;
    border: none;
    background: none;
    outline: none;
}
.yg-select-back-lp,
.yg-select-back-lp:active,
.yg-select-back-lp:focus,
.yg-select-back-lp:hover {
    position: fixed;
    left: -440px;
    top: 30px;
    width: 40px;
    height: 40px;
    border: none;
    background: none;
    outline: none;
}
.yg-select-back-cards,
.yg-select-back-cards:active,
.yg-select-back-cards:focus,
.yg-select-back-cards:hover {
    width: 40px;
    height: 40px;
    border: none;
    background: none;
    outline: none;
}
.transfer-modal {
    position: absolute;
    inset: 40px;
    background: rgb(255, 255, 255);
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 20px;
    right: 0;
    top: 0;
    bottom: 0;
    left: 60%;
    border-radius: 32px 0 0 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 80px;
}
.transfer-modal-overlay {
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    background: rgb(30 26 56 / 63%);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.yg-balance-headername-min {
    font-size: 10px;
    font-weight: bold;
}
.yg-balance-headername-min-xs {
    font-size: 10px;
}
.yg-card-number-min {
    font-size: 30px;
    color: $black-neutral;
}
.yg-card-number-min-gray {
    font-size: 30px;
    color: $gray-light-neutral;
}
.yg-card-number-min-s {
    font-size: 20px;
    color: $black-neutral;
    font-weight: bold;
}
.signin-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.yg-password-form-div {
    padding-bottom: 1.5rem;
}
.yg-password-form-div-entnewpass {
    width: 80%;
    padding-bottom: 1.5rem;
}
.card-button,
.card-button:active,
.card-button:focus,
.yg-btn-red:hover,
.yg-btn-red:focus,
.yg-btn-red:active {
    background: none;
    outline: none;
    border: none;
    width: -webkit-fill-available;
}
.card-button-goals,
.card-button-goals:active,
.card-button-goals:focus {
    background: none;
    outline: none;
    border: none;
    padding: 0;
    width: -webkit-fill-available;
}
.goforward-button,
.goforward-button:active,
.goforward-button:focus {
    background: none;
    outline: none;
    border: none;
    padding: 0;
}
.navmenu-button,
.navmenu-button:active,
.navmenu-button:focus {
    background: none;
    outline: none;
    border: none;
    display: flex;
    align-items: center;
}
.yg-expenses-form-div {
    background: $black-neutral;
    padding: 10px 15px;
    border-radius: 12px;
    width: 100%;
}
.yg-expenses-form-div-input {
    width: 100%;
}
.yg-expenses-form-main{
    display: flex;
    width: 100%;
    background: $black-neutral;
    padding: 10px 15px;
    border-radius: 12px;
    cursor: pointer;
}
.input-calendar{
    background: $black-neutral;
    border: none;
    pointer-events: none;
    color: white;
    font-weight: bold;
}
.yg-expenses-form-div-details {
    background: $black-neutral;
    padding: 10px 15px;
    border-radius: 12px;
    margin-top: 10px;
}
.yg-expenses-form-div-details-white {
    background: $white;
    padding: 10px 15px;
    border-radius: 12px;
    margin-top: 10px;
    border: 2px black solid;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: -webkit-fill-available;
    outline: none;
}
.yg-expenses-form-div-details-secondary-white,
.yg-expenses-form-div-details-secondary-white:focus,
.yg-expenses-form-div-details-secondary-white:active {
    background: $white-secondary;
    padding: 10px 15px;
    border-radius: 12px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: -webkit-fill-available;
    border: none;
    outline: none;
}
.yg-expenses-form-div-details-secondary-green,
.yg-expenses-form-div-details-secondary-green:focus,
.yg-expenses-form-div-details-secondary-green:active {
    background: $green-light;
    padding: 10px 15px;
    border-radius: 12px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: -webkit-fill-available;
    border: none;
    outline: none;
}
.yg-expenses-form-div-details-light-blue {
    background: $blue-light;
    padding: 10px 15px;
    border-radius: 12px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: -webkit-fill-available;
    border: inherit;
    outline: none;
}
.yg-expenses-form-div-details-secondary {
    background: $blue-secondary;
    padding: 10px 15px;
    border-radius: 12px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: -webkit-fill-available;
    border: inherit;
    outline: none;
}
.yg-expenses-form-div-details-secondary-variant {
    background: $blue-secondary-variant;
    padding: 10px 15px;
    border-radius: 12px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: -webkit-fill-available;
    border: inherit;
    outline: none;
}
.yg-expenses-form-div-details-white-primary {
    background: $white-primary;
    padding: 10px 15px;
    border-radius: 12px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: -webkit-fill-available;
    border: inherit;
    outline: none;
}
.expenses-svg {
    padding-right: 15px;
    width: 42px;
}
.expenses-svg-left {
    padding-left: 15px;
    width: 42px;
}
.expenses-svg-min {
    padding-right: 8px;
    width: 30px;
}
.expenses-chart-budget-child {
    width: 170px;
    padding: 15px 0;
    text-align: center;
}
.expenses-chart {
    width: 100px;
    padding: 15px 0;
    text-align: center;
}
.expenses-with-chart {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.yg-form {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.yg-form-100 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.yg-form-edit-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.yg-headline-label {
    font-size: 12px;
    color: $gray-light-neutral;
    text-align: center;
}
.yg-headline-label-dark {
    font-size: 16px;
    color: $black-neutral;
    font-weight: bold;
}
.yg-btn-form {
    padding-top: 35px;
}
.yg-btn-form-lets-start {
    padding-top: 30px;
    display: flex;
    width: 100%;
}
.yg-btn-form-delete {
    bottom: 30px;
    position: fixed;
}
.btn-dp, .btn-dp:active, .btn-dp:hover, .btn-dp:focus{
    display: flex;
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
}
.btn-as-link,
.btn-as-link:active,
.btn-as-link:hover,
.btn-as-link:focus {
    background: transparent !important;
    border: none;
    outline: none;
}
.yg-link:hover,
.yg-link-dark:hover {
    text-decoration: none;
}
.yg-link {
    display: flex;
    justify-content: center;
    padding: 0.5rem 0 0 0;
    color: $blue-secondary;
}
.yg-link-dark {
    display: flex;
    justify-content: center;
    padding-top: 0.5rem;
    color: $black-neutral;
}
.yg-dashboard-form {
    background-color: white;
    border-radius: 32px 0 0 32px;
    padding: 45px 0;
}
.yg-card-blue {
    border: none;
    background-color: #effaff;
    border-radius: 20px;
    padding: 1rem;
}
.yg-card-gradient {
    border: none;
    background: linear-gradient(90deg, rgb(239, 250, 255) 35%, rgb(255, 241, 234) 100%);
    border-radius: 20px;
    padding: 1rem;
    justify-content: center;
}
.yg-card-orange {
    border: none;
    background-color: #fff1ea;
    border-radius: 20px;
    padding: 1rem;
}
.yg-card-orange:hover,
.yg-card-blue:hover,
.yg-selfcard-orange:hover,
.yg-selfcard-blue:hover {
    box-shadow: 0 8px 15px rgb(0 0 0 / 49%);
}
.yg-card-header {
    background-color: transparent;
    border-bottom: none;
    font-size: 20px;
    font-weight: bold;
    color: $black-neutral;
}
.yg-payments-span {
    background-color: transparent;
    border-bottom: none;
    font-size: 10px;
    font-weight: bold;
}
.yg-card-allowance-blue {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid #cbeeff;
    padding: 10px 0;
}
.yg-card-allowance-orange {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid #ffe0cf;
    padding: 10px 0;
}
.yg-card-expenses {
    margin: 15px 0 0 0;
    border-radius: 12px;
    padding: 15px 30px;
    background-color: white;
}

.yg-row-cards {
    margin: 0 1rem 1rem 1rem;
}
.yg-card-number-blue {
    font-size: 48px;
    color: $blue-secondary;
}
.yg-card-number-orange {
    font-size: 48px;
    color: $orange-primary;
}
.yg-card-number-blue-s {
    font-size: 24px;
    color: $blue-secondary;
}
.yg-card-number-orange-s {
    font-size: 24px;
    color: $orange-primary;
}
.yg-card-number-small {
    font-size: 24px;
}
.yg-card-alwn-costs-h {
    font-size: 10px;
    color: #2c2c2c;
}
.pound-gray {
    font-size: 10px;
    color: $gray-light-neutral;
}
.yg-card-alwn-costs-q {
    font-size: 24px;
    color: $gray-light-neutral;
}
.yg-modal-child {
    padding: 10px 20px;
}
.yg-modal-child-max {
    padding: 15px 25px;
}
.btn-onclose-modal,
.btn-onclose-modal:focus,
.btn-onclose-modal:active {
    position: absolute;
    top: 25px;
    right: 25px;
    background: none;
    border: none;
    outline: none;
}
.yg-card-alwn-costs-q-small {
    font-size: 20px;
    color: $gray-light-neutral;
}
.yg-card-alwn-costs-q-xs {
    font-size: 16px;
    color: $gray-light-neutral;
}

.error-msg {
    color: red;
}
.error-icon {
    color: red;
    padding: 0 10px 0 0;
}
.success-icon {
    color: green;
    padding: 0 10px 0 0;
}
.validator {
    font-size: 12px;
}
.yg-card-avatar {
    width: 60px;
    height: 60px;
    border-radius: 100%;
}
.yg-card-avatar-min {
    width: 32px;
    height: 32px;
    border-radius: 100%;
}
.yg-card-avatar-max {
    width: 48px;
    height: 48px;
    border-radius: 100%;
}
.yg-card-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid white;
}
.yg-card-head-main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid white;
}
.yg-card-head:hover {
    box-shadow: 0 8px 15px rgb(0 0 0 / 49%);
}
.yg-card-head-gradient {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
.yg-card-head-transfer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.yg-balance-header {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.yg-balance-header-left {
    display: flex;
    flex-direction: column;
    align-items: baseline;
}
.yg-card-alwn-days {
    font-size: 14px;
    color: $gray-light-neutral;
}
.yg-cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
}
.yg-greeting-parent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
}
.select-back-black {
    margin: 0.5rem 1rem -1rem 0;
}
.button-back-div {
    padding: 2rem 0;
}
.yg-greeting-parent-name {
    font-size: 36px;
}
.yg-greeting-name {
    font-size: 36px;
}
.yg-greeting-parent-balance {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.yg-greeting-parent-balance-number {
    font-size: 48px;
}
.yg-parentdb-buttons {
    display: flex;
    padding: 1rem 0 2rem 0;
}

.yg-parentdb-btn,
[type="submit"]:focus {
    background: white !important;
    color: black !important;
    border: 1px solid $black-neutral;
    padding: 5px 50px;
    border-radius: 12px;
    margin: 0 25px 0 0;
    display: flex;
    align-items: center;
    box-shadow: none !important;
    outline: none !important;
}
.yg-parentdb-btn:hover{
    border: none;
    border: 1px solid $black-neutral;
    box-shadow: 0 8px 15px rgb(0 0 0 / 49%) !important;
}
.yg-cc-progressbars-orange {
    display: flex;
    align-items: center;
    padding: 10px 0;
    border-bottom: 2px solid #ffe0cf;
}
.yg-cc-progressbars-blue {
    display: flex;
    align-items: center;
    padding: 10px 0;
    border-bottom: 2px solid #cbeeff;
}
.yg-cc-progressbars-orange-nb {
    display: flex;
    align-items: center;
    padding: 10px 0;
}
.yg-cc-progressbars-blue-nb {
    display: flex;
    align-items: center;
    padding: 10px 0;
}
.yg-cc-pb-icon {
    padding: 0 20px 0 0;
}
.yg-cc-pb {
    width: 100%;
}
.yg-cc-pb-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 5px 0;
}
.yg-pb-blue {
    border-radius: 6px;
    background-color: #cbeeff;
    height: 10px;
}
.yg-pb-orange {
    border-radius: 6px;
    background-color: #ffe0cf;
    height: 10px;
}
.yg-pb-blue-selfcard {
    width: 100%;
    border-radius: 6px;
    background-color: #cbeeff;
    height: 10px;
}
.yg-pb-orange-selfcard {
    width: 100%;
    border-radius: 6px;
    background-color: #ffe0cf;
    height: 10px;
}
.yg-pb-green-selfcard {
    width: 100%;
    border-radius: 6px;
    background-color: $green-lighter;
    height: 10px;
}
.yg-pbb-blue {
    background-color: $blue-secondary;
    height: 10px;
}
.yg-pbb-orange {
    background-color: #ff5b02;
    height: 10px;
}
.yg-pbb-green {
    background-color: $green-success;
    height: 10px;
}
.parent-profile-svg {
    width: 20px;
}
.yg-radio-form-chores {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}
.yg-check {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 24px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
/* Hide the browser's default checkbox */
.yg-check input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    border-radius: 15px;
    border: 2px $blue-secondary solid;
}
/* On mouse-over, add a grey background color */
.yg-check:hover input ~ .checkmark {
    background-color: #ccc;
}
/* When the checkbox is checked, add a blue background */
.yg-check input:checked ~ .checkmark {
    background-color: $blue-secondary;
}
/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}
/* Show the checkmark when checked */
.yg-check input:checked ~ .checkmark:after {
    display: block;
}
/* Style the checkmark/indicator */
.yg-check .checkmark:after {
    left: 7px;
    top: 4px;
    width: 7px;
    height: 12px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
/* Green */
.yg-check-green {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 24px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.yg-check-green input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
.checkmark-green {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    border-radius: 15px;
    border: 2px $green-success solid;
}
.yg-check-green:hover input ~ .checkmark-green {
    background-color: #ccc;
}
.yg-check-green input:checked ~ .checkmark-green {
    background-color: $green-success;
}
.checkmark-green:after {
    content: "";
    position: absolute;
    display: none;
}
.yg-check-green input:checked ~ .checkmark-green:after {
    display: block;
}
.yg-check-green .checkmark-green:after {
    left: 7px;
    top: 4px;
    width: 7px;
    height: 12px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
/* Orange */
.yg-check-orange {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 24px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.yg-check-orange input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
.checkmark-orange {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    border-radius: 15px;
    border: 2px $orange-primary solid;
}
.yg-check-orange:hover input ~ .checkmark-orange {
    background-color: #ccc;
}
.yg-check-orange input:checked ~ .checkmark-orange {
    background-color: $orange-primary;
}
.checkmark-orange:after {
    content: "";
    position: absolute;
    display: none;
}
.yg-check-orange input:checked ~ .checkmark-orange:after {
    display: block;
}
.yg-check-orange .checkmark-orange:after {
    left: 7px;
    top: 4px;
    width: 7px;
    height: 12px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
.yg-nav-bar {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 35px 0;
    position: fixed;
    top: 1rem;
    left: 1.5rem;
}
.yg-navmenu-avatar {
    width: 32px;
    height: 32px;
    border-radius: 100%;
}
.navbar-bottom {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    color: white;
    align-items: center;
    grid-row-gap: 20px;
}
.navbar-top {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    color: white;
    align-items: center;
    grid-row-gap: 20px;
}
.navbar-top-desktop {
    display: grid;
    color: white;
    align-items: center;
    grid-row-gap: 20px;
}
.navbar-top-mobile {
    display: grid;
    color: white;
    align-items: center;
    grid-row-gap: 20px;
    margin-left: -5rem;
}

a,
a:focus,
a:active,
a:hover {
    text-decoration: none;
    color: inherit;
}

.yg-childcard,
.yg-childcard:focus,
[type="submit"]:focus {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}

.yg-topnav-mm-btn,
.yg-topnav-mm-btn:focus,
[type="submit"]:focus {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}

.yg-carddtls-head {
    display: flex;
    align-items: center;
    border-bottom: 2px solid white;
    padding: 0 0 10px 0;
}

.yg-carddtls-header {
    background-color: transparent;
    border-bottom: none;
    font-size: 20px;
}

.yg-selfcard-header {
    background-color: transparent;
    border-bottom: none;
    font-size: 20px;
}

.yg-carddtls-expenses-orange {
    display: flex;
    flex-direction: column;
}

.yg-carddtls-expenses-blue {
    display: flex;
    flex-direction: column;
}
.yg-carddtls-expenses {
    display: flex;
    flex-direction: column;
    border: 2px black solid;
    border-radius: 12px;
    padding: 5px 15px;
}
.yg-carddtls-savingaccount {
    display: flex;
    flex-direction: column;
}
.yg-carddtls-expdtls-orange {
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #ffe0cf;
    align-items: center;
    padding: 10px 0;
}
.yg-carddtls-expdtls-orange-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    margin: 10px 0;
    border-radius: 12px;
}
.yg-carddtls-expdtls-black {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    margin: 10px 0;
    border-radius: 12px;
    border: 2px black solid;
}
.yg-balance-header-img-details{
    border-radius: 40px;
}
.yg-carddtls-expdtls-orange-last {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
}
.yg-carddtls-expdtls-blue {
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #cbeeff;
    align-items: center;
    padding: 10px 0;
}
.yg-carddtls-expdtls-blue-last {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
}
.yg-carddtls-expdtls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
}
.yg-card-number-small-time {
    font-size: 12px;
    color: $gray-light-neutral;
}
.yg-carddtls-exprow {
    display: flex;
    align-items: center;
}
.yg-balance-header-img {
    padding: 18px 8px 0 0;
}
.yg-balance-header-img-s {
    padding: 20px 10px 0 0;
}
.yg-topnav-mobile {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    padding: 0.98rem 1.5rem 0 1.5rem;
}
.yg-topnav-mb-logo {
    display: flex;
    justify-content: center;
}
.yg-topnav-mb-send {
    display: flex;
    justify-content: flex-end;
}
.yg-topnav-mobile-menu {
    padding-right: 15px;
}
.rdrDateDisplayWrapper{
    background: $black-neutral;
    border-radius: 12px;
}
.yg-parentdb-btn-dark,
.yg-parentdb-btn-dark:hover,
.yg-parentdb-btn-dark:focus,
[type="submit"]:focus {
    background: $black-neutral !important;
    color: white;
    border: 1px solid $black-neutral !important;
    padding: 5px 50px;
    border-radius: 12px;
    margin: 0 25px 0 0;
    display: flex;
    outline: none !important;
    align-items: center;
    box-shadow: none !important;
}
.yg-parentdb-nochild {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.yg-parentdb-nochildimg {
    border-radius: 18px;
    margin-bottom: 2rem;
}
.yg-cards-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}
.yg-selfcard-blue {
    border: none;
    background-color: #effaff;
    border-radius: 20px;
    padding: 10px 25px;
    margin-top: 1rem;
}
.yg-selfcard-orange {
    border: none;
    background-color: #fff1ea;
    border-radius: 20px;
    padding: 10px 25px;
    margin-top: 1rem;
}
.yg-selfcard-green {
    border: none;
    background-color: $green-light;
    border-radius: 20px;
    padding: 8px 25px;
    margin-top: 1rem;
}
.yg-selfcard-head {
    padding: 10px 0;
    display: flex;
    align-items: center;
    border-bottom: 2px solid white;
}
.yg-selfcard-alwn-blue {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
}
.yg-selfcard-alwn-orange {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
}
.yg-cards-grid-left {
    padding-right: 1rem;
}
.yg-cc-pb-selfcard {
    display: flex;
    align-items: center;
    padding: 10px 0 0 0;
}
.yg-cc-selfcard-topname {
    font-size: 20px;
}
.yg-selfcard-costs {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
}
.yg-code-partitioned {
    border: 0;
    font-size: 32px;
    width: 100%;
    background: $black-neutral;
    color: $white;
    border-radius: 8px;
    height: 80px;
    text-align: center;
}
.yg-code-partitioned-light {
    border: 0;
    font-size: 32px;
    width: 100%;
    background: $white-neutral;
    color: $white;
    border-radius: 8px;
    height: 80px;
    text-align: center;
}
.yg-code-partitioned-cem {
    border: 0;
    font-size: 32px;
    width: 100%;
    background: $black-neutral;
    color: $white;
    border-radius: 8px;
    height: 70px;
    text-align: center;
}
.yg-code-partitioned-cem-light {
    border: 0;
    font-size: 32px;
    width: 100%;
    background: $white-neutral;
    color: $white;
    border-radius: 8px;
    height: 70px;
    text-align: center;
}
.ta-c {
    text-align: center;
}
.yg-code-partitioned-div {
    grid-template-columns: repeat(4, 1fr);
    display: grid;
    padding: 25px 0;
    grid-gap: 20px;
    width: 65%;
}
.yg-code-partitioned-div-cem {
    grid-template-columns: repeat(6, 1fr);
    display: grid;
    padding: 25px 0;
    grid-gap: 5px;
    width: 100%;
}
.yg-code-partitioned-div-s {
    grid-template-columns: repeat(6, 1fr);
    display: grid;
    padding: 25px 0;
    grid-gap: 8px;
    width: 100%;
}
.yg-codelogin-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.yg-codeform-link {
    padding-left: 5px;
    font-weight: bold;
    color: $orange-primary;
    background: transparent;
    outline: none;
}
.yg-codeform-link-disabled {
    padding-left: 5px;
    font-weight: bold;
    color: $gray-light-neutral;
    background: transparent;
    outline: none;
}
.yg-codeform-link:hover,
.yg-codeform-link:focus,
.yg-codeform-link:hover:active {
    outline: none;
}
.rdrDefinedRangesWrapper {
    display: none !important;
}
.yg-notreceivedform {
    display: flex;
    align-items: center;
    padding-top: 30px;
    font-size: 12px;
}
.yg-btn-welcomeform {
    border: none;
    width: 100%;
    border-radius: 16px;
    box-shadow: none !important;
    padding: 30px 0;
    margin: 20px 0 0 0;
}
.welcome-quote {
    font-size: 48px;
}
.welcome-buttons {
    margin: 7rem 0 11rem 0;
}
.yg-link-welcomeform {
    color: #bababa;
}
.yg-signinform-main {
    margin: 7rem 0 8rem 0;
}
.yg-navbar-login-left {
    flex-direction: column !important;
}
.yg-navbar-login-left-div {
    justify-content: center;
    text-align: flex-end;
    font-size: 20px;
}
.yg-blue-20 {
    font-size: 20px;
    color: $blue-secondary;
}
.panel-title {
    font-size: 20px;
    color: $orange-primary;
    font-weight: unset;
}
.pure-modal-backdrop .pure-modal {
    width: 30rem;
    border-radius: 20px;
    background-color: $black-neutral;
    padding: 30px 10px;
}
.pure-modal .panel-heading {
    background: transparent;
}
.pure-modal .panel-body {
    background-color: transparent;
    padding: 15px 0 5px 0;
    color: $white-primary;
}
.pure-modal .panel-footer {
    background-color: transparent;
    padding: 25px;
}
.pure-modal .panel-title {
    padding: 0;
}
.create-account-form-group {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px 30px;
    width: 100%;
}
.edit-profile-form-group {
    display: grid;
    grid-gap: 20px;
    width: 100%;
}
.create-account-form-header {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    width: 80%;
    padding: 20px 0 25px 0;
}
.create-account-form-h1 {
    font-size: 46px;
    color: $black-neutral;
}
.yg-form-input,
.yg-form-input:focus,
.yg-form-input:hover,
.yg-form-input:active {
    background: transparent;
}
.react-switch-bg {
    border: 2px $orange-primary solid !important;
}
.logo-single-big {
    padding-bottom: 40px;
}
.image-upload > input {
    display: none;
}
.yg-profile-pic {
    width: 40px;
    height: 40px;
    margin-top: 10px;
    cursor: pointer;
}
.yg-profile-pic-uploaded {
    width: 40px;
    height: 40px;
    margin-top: 10px;
    cursor: pointer;
    border-radius: 100%;
}
.yg-codelogin-headline-main {
    color: $orange-primary;
    font-weight: bold;
}
.card-button {
    padding: 0;
}
._2Jtxm ._1hzhf {
    font-size: 10px !important;
    font-weight: bold;
    width: 55px;
}
.css-1pahdxg-control{
    border:none !important;
    box-shadow: none !important;
    background: linear-gradient(90deg, #effaff 35%, #fff1ea 100%)  !important;
    border-radius: 12px !important;
    cursor: pointer !important;
}
.css-319lph-ValueContainer {
    padding: 0 !important;
}
.css-1okebmr-indicatorSeparator {
    display: none;
}
.css-1s2u09g-control {
    background: linear-gradient(90deg, #effaff 35%, #fff1ea 100%)  !important;
    border-radius: 12px !important;
    border:none !important;
    cursor: pointer !important;
}
.css-1s2u09g-control:hover{
    box-shadow: 0 8px 15px rgb(0 0 0 / 49%);
}
@media (max-width: $tablet) {
    .yg-login-row {
        display: unset;
        height: unset;
        background-color: transparent;
    }
    .create-account-form-h1 {
        font-size: 35px;
        color: $black-neutral;
    }
    .welcome-buttons {
        margin: 4rem 0 7rem 0;
    }
    .yg-login-left {
        display: none;
    }
    .yg-parentdb-nochildimg {
        border-radius: 18px;
        margin-bottom: 2rem;
        width: 60%;
    }
    .yg-cards {
        display: flex;
        flex-direction: column;
    }
    .yg-greeting-parent-name {
        display: none;
    }
    .yg-parentdb-buttons {
        display: none;
    }
    .yg-dashboard-form {
        max-width: 100%;
    }
    .yg-card-orange {
        margin: 0 0 1rem 0;
    }
    .yg-card-blue {
        margin: 0 0 1rem 0;
    }
    .yg-greeting-parent-balance-number {
        font-size: 30px;
    }
    .yg-card-alwn-costs-q {
        font-size: 16px;
    }
    .yg-card-avatar {
        width: 40px;
        height: 40px;
    }
    .yg-card-header {
        font-size: 20px;
        padding: 0.75rem 0.9rem;
    }
    .yg-card-number-orange {
        font-size: 30px;
    }
    .yg-card-number-blue {
        font-size: 30px;
    }
    .yg-balance-headername {
        font-size: 10px;
        font-weight: bold;
    }
    .yg-card-head {
        padding-bottom: 10px;
    }
    .yg-card-alwn-costs-q-small {
        font-size: 10px;
    }
    .yg-balance-headerleft-name {
        font-size: 10px;
        font-weight: bold;
    }
    .yg-card-number-small {
        font-size: 16px;
    }
    .yg-card-alwn-days {
        font-size: 10px;
    }
    .yg-cc-icon {
        width: 35px;
        height: 35px;
    }
    .yg-pb-orange {
        height: 10px;
    }
    .yg-pb-blue {
        height: 10px;
    }
    .yg-pb-orange-selfcard {
        height: 10px;
    }
    .yg-pb-blue-selfcard {
        height: 10px;
    }
    .yg-cc-topname {
        font-size: 10px;
        font-weight: bold;
    }
    .yg-cc-selfcard-topname {
        font-size: 10px;
        font-weight: bold;
    }
    .yg-cc-pb-icon {
        padding: 0 20px 0 0;
    }
    .yg-greeting-parent-balance-name {
        font-size: 10px;
        font-weight: bold;
    }
    .yg-greeting-parent {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .yg-nav-bar {
        transition: all 0.5s ease;
        background: $black-neutral;
        border-radius: 0 32px 32px 0;
        margin-top: -4.1rem;
        opacity: 0.9;
        font-size: 14px;
        position: unset;
        top: unset;
        left: unset;
    }
    .yg-dashboard-left {
        position: fixed;
        z-index: 100;
        padding: 0;
        -webkit-animation: fadeIn 0.5s;
        animation: fadeIn 0.5s;
    }
    .yg-dashboard-left-none {
        display: none;
    }
    @-webkit-keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    .navbar-mb-logo {
        width: 110px;
        height: 110px;
    }
    .yg-carddtls-header {
        padding: 0.75rem 0.9rem;
    }
    .yg-selfcard-header {
        padding: 0.75rem 0.9rem;
        font-size: 12px;
        font-weight: bold;
    }
    .yg-cards-grid {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }
    .yg-cards-grid-left {
        padding-right: 0;
    }
    .yg-signinform-main {
        margin: 5rem 0 6rem 0;
    }
    .welcome-quote {
        font-size: 32px;
    }
    .create-account-form-group {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 10px 30px;
        width: 100%;
    }
    .signin-form {
        margin-top: -5rem;
    }
    .toggle {
        width: 50%;
    }
    .yg-select-back-lp,
    .yg-select-back-lp:active,
    .yg-select-back-lp:focus,
    .yg-select-back-lp:hover {
        left: 15px;
    }
    .connect-bank-from-child {
        padding-top: 8rem !important;
        justify-content: unset;
    }
    .w-50 {
        width: 80% !important;
    }
    .code-form {
        padding-left: 0;
        justify-content: unset;
    }
    .rs-steps-item-title {
        width: 35px;
    }
    .toast-container-mobile {
        margin-bottom: 11rem;
    }
    .create-accout-form {
        padding-left: 0;
    }
    .steps-line {
        margin: -70px 23px 55px 26px;
    }
    .rs-steps-horizontal .rs-steps-item:not(:first-child) {
        padding-left: 40px;
    }
    .rs-steps-horizontal .rs-steps-item {
        padding-left: 40px;
    }
    .rs-steps-horizontal .rs-steps-item .rs-steps-item-icon-wrapper {
        left: 0;
    }
    .rs-steps-horizontal .rs-steps-item:not(:first-child) .rs-steps-item-icon-wrapper {
        left: 0px;
    }
    .back-button-signup {
        top: 110px;
        left: 26px;
        bottom: unset;
    }
    .yg-btn-thin {
        margin: 0 5px;
        font-size: 14px;
    }
    .transfer-modal {
        left: 10%;
    }
    .transfer-modal-overlay {
        z-index: 101;
    }
    .yg-loginpage-row {
        background-image: none;
    }
    .w-80-mobile {
        width: 80%;
    }
    .yg-greeting-name {
        font-size: 28px;
    }
    .rb-label-dashboard {
        padding: 7px 5px !important;
    }
    .yg-btn,
    .yg-parentdb-btn-dark {
        padding: 7px 35px;
    }
    .card-header {
        padding: 0.75rem 0.25rem;
    }
    .yg-card-number-min-s {
        font-size: 15px;
    }
    .rb-label {
        padding: 10px 12px !important;
    }
}

@media (min-width: $tablet) {
    .yg-topnav-mobile {
        display: none;
    }
}
